import React from "react";
import Navbaro from "../components/Navbar";
import Footer from "../components/footer";
import AOS from 'aos';
import { useEffect } from "react";

const Nutrition = () => {
    useEffect(() => {
        AOS.init();
      }, []);

    return(
        <div className="wrap derma">
            <Navbaro></Navbaro>
            <div className="position-relative" id="top">
                <img src="Dermatology.png" alt="" className="img-fluid darker" />
                <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-50"></div>
                <div className="position-absolute top-50 start-50 translate-middle text-center">
                    <p className="text-white display-2" data-aos="fade-left" data-aos-duration="3000">Nutrition</p>
                </div>
            </div>
            <div className="container-fluid my-5" data-aos="fade-up" data-aos-duration="3000">
                <div className="row">
                    <div className="col-sm-5 col-md-4 col-lg-4 col-xl-5 col-xxl-4 offset-lg-1 my-lg-5 my-md-5 my-sm-5">
                        <img src="/Stretch.jpg" alt="" className="service "/>
                    </div>
                    <div className="col-sm-7 col-md-7 offset-md-1 col-lg-6 col-xl-5 offset-xxl-1 offset-xl-1  offset-lg-1 my-lg-5  my-xl-5 my-xxl-0 my-sm-3">
                        <p className="display-4 my-xxl-5 serviceTitle my-md-3">Stretch Mark Care</p>
                        <h4 className="my-xxl-4 col-xxl-12 paragraph">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio, harum aliquam quo enim consequuntur inventore. Perferendis, sapiente. Neque consequatur deleniti alias vel animi! Ab neque earum exercitationem quisquam maiores? Impedit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint illo, magnam quaerat voluptas commodi nam quidem recusandae odit aliquid expedita quisquam deserunt nostrum nobis itaque qui quam aperiam quos illum!</h4>
                        <button className="but my-xxl-3 my-lg-2 col-lg-4">Book Now</button>
                    </div>
                </div>
            </div>
            <hr className="line"/>
            <div className="container-fluid my-5" data-aos="fade-up" data-aos-duration="3000">
                <div className="row">
                <div className="col-sm-7 col-md-7 col-lg-6 col-xl-5 offset-xxl-1 offset-xl-1  offset-lg-1 my-lg-5  my-xl-5 my-xxl-0 my-sm-3">
                        <p className="display-4 my-xxl-5 serviceTitle my-md-3 serviceTitle">So-Check Test</p>
                        <h4 className="my-xxl-4 col-xxl-12 paragraph">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio, harum aliquam quo enim consequuntur inventore. Perferendis, sapiente. Neque consequatur deleniti alias vel animi! Ab neque earum exercitationem quisquam maiores? Impedit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint illo, magnam quaerat voluptas commodi nam quidem recusandae odit aliquid expedita quisquam deserunt nostrum nobis itaque qui quam aperiam quos illum!</h4>
                        <button className="but my-xxl-3 my-lg-2 col-lg-4">Book Now</button>
                    </div>
                    <div className="col-sm-5 col-md-4 col-lg-4 col-xl-5 col-xxl-4 offset-xxl-1 offset-xl-1 my-lg-5 my-md-5 my-sm-5 order-first order-md-last order-sm-last order-lg-last">
                        <img src="/Socheck.jpg" alt="" className="service"/>
                    </div>
                </div>
            </div>
            <hr className="line"/>
            <div className="container-fluid my-5" data-aos="fade-up" data-aos-duration="3000">
                <div className="row">
                    <div className="col-sm-5 col-md-4 col-lg-4 col-xl-5 col-xxl-4 offset-lg-1 my-lg-5 my-md-5 my-sm-5">
                        <img src="/Cellulite.jpg" alt="" className="service"/>
                    </div>
                    <div className="col-sm-7 col-md-7 offset-md-1 col-lg-6 col-xl-5 offset-xxl-1 offset-xl-1  offset-lg-1 my-lg-5  my-xl-5 my-xxl-0 my-sm-3">
                        <p className="display-4 my-xxl-5 serviceTitle my-md-3 serviceTitle">Cellulite Treatment</p>
                        <h4 className="my-xxl-4 col-xxl-12 paragraph">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio, harum aliquam quo enim consequuntur inventore. Perferendis, sapiente. Neque consequatur deleniti alias vel animi! Ab neque earum exercitationem quisquam maiores? Impedit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint illo, magnam quaerat voluptas commodi nam quidem recusandae odit aliquid expedita quisquam deserunt nostrum nobis itaque qui quam aperiam quos illum!</h4>
                        <button className="but my-xxl-3 my-lg-2 col-lg-4">Book Now</button>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            
        </div>
    )
}
export default Nutrition;