import * as React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import StaffNav from '../components/StaffNav';
import { useEffect } from 'react';
import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import baseURL from '../../baseURL';

 function Sidebar() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
  const [doctorsData, setDoctorsData] = useState([]);


  const navigate = useNavigate();


  useEffect(() => {
    axios
      .get(`${baseURL}/api/reservation/fetchAllDoctorsForStaff`, {
        withCredentials: true,
      })
      .then((response) => {
        setDoctorsData(response.data.doctors);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleDoctorButtonClick = (doctorName) => {

    axios
      .post(`${baseURL}/api/doctor/getDoctorId`, { doctorName }, {
        withCredentials: true,
      }) // Adjust the API endpoint
      .then((response) => {
        const doctorId = response.data.doctorId;
        navigate(`/modify/calendar?doctorId=${doctorId}`);
      })
      .catch((error) => {
        console.error('Error sending request:', error);
      });
  };


  const handleAccordionSelect = (eventKey) => {
    console.log(eventKey);
    setSelectedSpecialty(eventKey);
  };


  return (
    <div className="wrap">
      <StaffNav></StaffNav>
      <div className="container marge">
        <h1>Modify Shifts</h1>
      <Accordion defaultActiveKey="Dermatology" onSelect={handleAccordionSelect}>
      <Accordion.Item eventKey="Dermatology">
        <Accordion.Header>Dermatology</Accordion.Header>
        <Accordion.Body>
        {doctorsData
                          .filter(
                            (doctor) => doctor.Speciality === "Dermatology",
                          )
                          .map((doctor, index) => (
                              <button className='staffBut mx-2' onClick={() => handleDoctorButtonClick(doctor.Name)}>
                               Dr. {doctor.Name}
                               </button>
                            
                          ))}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="Pediatrics">
        <Accordion.Header>Pediatrics</Accordion.Header>
        <Accordion.Body>
        {doctorsData
                          .filter(
                            (doctor) => doctor.Speciality === "Pediatrics",
                          )
                          .map((doctor, index) => (
                              <button className='staffBut mx-2' onClick={() => handleDoctorButtonClick(doctor.Name)}>
                               Dr. {doctor.Name}
                               </button>
                            
                          ))}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="Dentistry">
        <Accordion.Header>Dentistry</Accordion.Header>
        <Accordion.Body>
        {doctorsData
                          .filter(
                            (doctor) => doctor.Speciality === "Dentistry",
                          )
                          .map((doctor, index) => (
                              <button className='staffBut mx-2' onClick={() => handleDoctorButtonClick(doctor.Name)}>
                               Dr. {doctor.Name}
                               </button>
                            
                          ))}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="Nutrition">
        <Accordion.Header>Nutrition</Accordion.Header>
        <Accordion.Body>
        {doctorsData
                          .filter(
                            (doctor) => doctor.Speciality === "Nutrition",
                          )
                          .map((doctor, index) => (
                              <button className='staffBut mx-2' onClick={() => handleDoctorButtonClick(doctor.Name)}>
                               Dr. {doctor.Name}
                               </button>
                            
                          ))}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="Laser">
        <Accordion.Header>Laser</Accordion.Header>
        <Accordion.Body>
        {doctorsData
                          .filter(
                            (doctor) => doctor.Speciality === "Laser",
                          )
                          .map((doctor, index) => (
                              <button className='staffBut mx-2' onClick={() => handleDoctorButtonClick(doctor.Name)}>
                               Dr. {doctor.Name}
                               </button>
                            
                          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
    </div>
  );
}; 
export default Sidebar ; 