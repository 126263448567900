import React from 'react';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { Routes , Route } from 'react-router-dom';
import Home from './patient/home/Home'
import Doctors from './patient/about/AboutDoctors';
import Dermatology from './patient/services/Dermatology';
import Nutrition from './patient/services/Nutrition';
import Dentistry from './patient/services/Dentistry';
import Login from './patient/login-signup/Login';
import Profile from './patient/profile/Profile';
import StaffLogin from './staffPortal/login/StaffLogin';
import Forget from './patient/forget-reset/Forget';
import ResPassword from './patient/forget-reset/Respassword';
import Calendar from './staffPortal/shifts/Calendar';
import Sidebar from './staffPortal/shifts/Sidebar';
import StaffViewRes from './staffPortal/viewReservations/StaffViewRes';
import NewRes from './patient/reservation/newRes';
import AddPoints from './staffPortal/addPoints/AddPoints';
import SingleDoctor from './patient/about/singleDoctor';
import { I18nextProvider } from 'react-i18next';
import i18n from './translation/i18n';
import { createRoot } from 'react-dom/client'; // Import createRoot

const root = document.getElementById('root');

// Use createRoot instead of ReactDOM.render
const rootInstance = createRoot(root);



rootInstance.render(
  <I18nextProvider i18n={i18n}>
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<Doctors/>} />
        <Route path="/derma" element={<Dermatology/>} />
        <Route path="/nutrition" element={<Nutrition/>} />
        <Route path="/dentistry" element={<Dentistry/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/profile" element={<Profile/>} />
        <Route path="/reservation" element={<NewRes/>} />
        <Route path="/stafflogin" element={<StaffLogin/>} />
        <Route path="/forgetpassword" element={<Forget/>} />
        <Route path="/resetPassword/:token" element={<ResPassword />}/>
        <Route path="/modify" element={<Sidebar />}/>
        <Route path="/modify/calendar" element={<Calendar />} />
        <Route path="/viewReservations" element={<StaffViewRes />}/>
        <Route path="/addPoints" element={<AddPoints />}/>
        <Route path="/doctor/:id" element={<SingleDoctor />}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
  </I18nextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
