import React, { useState } from "react";
import DataTable from "./components/Datatable";
import axios from "axios";
import { useEffect } from "react";
import StaffNav from "../components/StaffNav";
import { useNavigate } from "react-router-dom";
import baseURL from "../../baseURL";

const StaffViewRes = () => {

    const [requests, setRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const nav = useNavigate();


    useEffect(() => {
      // Make an Axios GET request to fetch data from your backend API
      axios.get(`${baseURL}/api/reservation/fetchReservations`, {
        withCredentials: true,
      })
      .then((response) => {
        setRequests(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          nav("/stafflogin");
        }
      });
    }, []);
    


  const handleRemove = (row) => {
    // Send a POST request to delete the reservation with ID in the request body
    const id = row.original.reservationId;
    axios
      .post(`${baseURL}/api/reservation/deleteReservation`, { id }, {
        withCredentials: true,
      })
      .then((response) => {
        setRequests((prevRequests) =>
      prevRequests.filter((request) => request.reservationId !== id)
    );
      console.log(requests);
        console.log(`Reservation removed successfully`);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          nav("/stafflogin");
        }
      });
  };


  

  const columns = [
    {
      Header: "Reservation ID",
      accessor: "reservationId",
    },
    {
      Header: "Patient Name",
      accessor: "patientName",
    },
    {
      Header: "Patient Phone Number",
      accessor: "patientPhoneNumber",
    },
    {
      Header: "Branch",
      accessor: "scheduleBranchName",
    },
    {
      Header: "Doctor Name",
      accessor: "doctorName",
    },
    {
      Header: "Shift Start Time",
      accessor: "scheduleStartTime",
    },
    {
      Header: "Shift End Time",
      accessor: "scheduleEndTime",
    },
    {
      Header: "Schedule Date",
      accessor: "scheduleDate",
      Cell: ({ value }) => {
        // Parse the UTC date string to a Date object
        const date = new Date(value);
  
        // Format the date as a string with only day, month, and year
        const formattedDate = date.toLocaleDateString();
  
        return formattedDate;
      },
    },
    {
      Header: "",
      accessor: "remove",
      disableFilters: true,
      Cell: ({ row }) => (
        <button onClick={() => handleRemove(row)} className="bot">Remove</button>
      ),
    },
  ];

  return (
    <div className="wrap home-section">
      {isLoading ? (
        <div className=" wrap">
            <div className="d-flex justify-content-center align-items-center min-vh-100">
              <div className="loader"></div>
            </div>
        </div>
      ) : (
        <>
          <StaffNav></StaffNav>
          <DataTable columns={columns} data={requests}></DataTable>
        </>
      )}
      
    </div>
  );
};

export default StaffViewRes;
