import React, { useState } from 'react';
import Navbaro from "../components/Navbar";
import Footer from "../components/footer";
import AOS from 'aos';
import { useEffect } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import baseURL from '../../baseURL';
import Lottie from 'react-lottie';
import { ProgressBar } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import diamondAnimation  from "../../lottie-animation/diamond.json"
import Form from "react-bootstrap/Form";

const Profile = () => {


  const [isLoading, setIsLoading] = useState(true);
  const [editEmail, setEditEmail] = useState(false);
  const [success,setSuccess] = useState("");
  const [error,setError] = useState("");
  const [Changed,setChanged] = useState(false);
  const [currentStep,setcurrentStep] = useState("");
  const [passwordSuccess,setPasswordSuccess] = useState("");
  const [passwordError,setPasswordError] = useState("");


  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: diamondAnimation ,
  };


    const [userInfo, setUserInfo] = useState('');

    useEffect(() => {
        AOS.init();
      }, []);


    const nav = useNavigate();



    useEffect(() => {
      axios
        .get(`${baseURL}/api/patient/getPatient`,  { withCredentials: true })
        .then((response) => {
          if (response.data.success) {
              setUserInfo(response.data.user);
              setIsLoading(false);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            nav("/login");
          }
        });
    }, [nav,Changed]);


    useEffect(() => {
      const progress = (userInfo.Points / 100) * 100;
      setcurrentStep(progress);
    }, [userInfo.Points]);


    const lottieContainerStyle = {
      margin: 0, 
    };


    const [validationErrors, setValidationErrors] = useState({
      email: "",
    });


    const [PassValidation, setPassValidation] = useState({
      pass: "",
      confirm : "",
      old : ""
    });
  
    const handleFieldChange = (field, value) => {
      setError("");
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [field]: validateField(value),
      }));
    };
  
    const validateField = (value) => {
          return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
            ? ""
            :"Please enter a valid email address" ;
    };




    const handlePassChange = (field, value) => {
      setPassValidation((prevErrors) => ({
        ...prevErrors,
        [field]: validatePass(field,value),
      }));
    };


    const validatePass = (field, value) => {
      switch (field) {
        case "old":
        return value.trim() === "" ? "Please enter your name" : "";

        case "pass":
          const isPasswordValid = value.length >= 8 && /[a-z]/.test(value) && /[A-Z]/.test(value);
          return isPasswordValid ? "" : "Password should be at least 8 characters with at least one lowercase and one uppercase letter.";
        // ... (other cases)
        case "confirm":
          const password = document.querySelector("#new").value;
          return value === password ? "" : "Passwords do not match.";
        default:
          return "";
      }
    };

    const isChangeDisabled = () => {
      return (
        PassValidation.pass ||
        PassValidation.confirm ||
        PassValidation.old
      );
    };
  
    const getPasswordClassName = () => {
      return isChangeDisabled() ? "bot col-lg-6 col-md-6 my-3 disabled" : "bot col-lg-6 col-md-6 my-3";
    };


    const changePassword = () => {


      const old = document.querySelector("#old").value;
      const newpass = document.querySelector("#new").value;
      const confirmnew = document.querySelector("#confirmnew").value;


    
        const oldValidation = validatePass("old", old);
        const passwordValidation = validatePass("pass", newpass);
        const confirmValidation = validatePass("confirm", confirmnew);

        const phoneNumber = userInfo.PhoneNumber;
  
    setPassValidation({
      old: oldValidation,
      pass: passwordValidation,
      confirm: confirmValidation,
    });
  
    if (
      passwordValidation ||
      confirmValidation ||
      oldValidation
    ) {
      
      return;
    }

      axios.post (`${baseURL}/api/auth/changepassword`, {old,newpass,confirmnew, phoneNumber},  { withCredentials: true } )
        .then((response) =>{
          if (response.data.success){
            setPasswordError("");
            setPasswordSuccess(response.data.message)
          }
        })
        .catch((error) =>{
          if (error.response && error.response.status === 401) {
            nav("/login");
          }
          setPasswordSuccess("");
          setPasswordError(error.response.data.message);
        })


    }


    const changeEmail = () => {

      const newEm = document.querySelector("#email").value;

      console.log(newEm);

        axios.post(`${baseURL}/api/patient/editEmail`,{
          currentEmail : userInfo.Email,
          newEmail : newEm
        },
        { withCredentials:true }).then((response) => {
          if (response.data.success){
            setChanged(true);
            setSuccess(response.data.message);
          }
        }).catch((error) => {
          if (error.response && error.response.status === 401) {
            nav("/login");
          }
          setError(error.response.data.message);
        })
    }

    const enterEdit = () => {
      setEditEmail(true);
    }

    const exitEdit = () => {
      setValidationErrors({
        email: "",
      });
      setSuccess("");
      setError("");
      setEditEmail(false);
    }




    return(
        <div className="wrap">
          {isLoading  ? ( 
            <div className="wrap">
            <div className="d-flex justify-content-center align-items-center min-vh-100">
             <div className="loader"></div>
           </div>
             </div>
          ) : (
            <>
            <div className="wrap bbbb">
              <Navbaro></Navbaro>
                    <div className="container d-flex flex-column justify-content-center mt-5">
                    <div className="row">
          <div className="col-12">
            <Card className='radi my-3'>
            <Card.Title>
                  <h1 className=' my-3 text-center' data-aos="fade-right" data-aos-duration="3000">You are Diamond</h1>
                  <h3 className='text-center text-muted'>Only 10 Points away from Platinum</h3>
                </Card.Title>

                <Card.Text className='my-3 mx-3'>
                <div className="container d-flex justify-content-center">
                <div style={lottieContainerStyle}>
                        <Lottie className=""
                          options={lottieOptions}
                          height={150}
                          width={150}
                        />
                      </div>
                      <ProgressBar
                        animated
                        max={100}
                        label={`${userInfo.Points} points`}
                        now={currentStep}
                        className="col-lg-6 col-md-6 col-6 text-center my-5 progressblue"
                      />
                      
                    </div>
                </Card.Text>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-6 col-12 " >
            <Card className='radi w-100 h-100'>
              <Card.Body>
                <Card.Title>
                  <h1  className=" mx-4" data-aos="fade-left" data-aos-duration="3000"  > Welcome Back  !</h1>
                </Card.Title>

                <Card.Text className='my-4 mx-4'>
                <h3  className=' my-5'><img src="/user.svg" alt="" style={{ width: '30px', height: '30px', marginRight: '10px' }}/>{userInfo.Name}</h3>
                  <h3  className=' my-5'><img src="/phone.svg" alt="" style={{ width: '30px', height: '30px', marginRight: '10px' }}/>0{userInfo.PhoneNumber}</h3>
                  {editEmail ? (
                    <>
                    <div className="container-fluid d-flex">
                      <img src="/email.svg" alt="" className='my-1' style={{ width: '30px', height: '30px', marginRight: '10px' }}/>

                      
                      <Form.Control
                        type="email"
                        placeholder="Enter your new email"
                        id='email'
                        className='mb-4'
                        required
                        onChange={(e) =>
                          handleFieldChange("email", e.target.value)
                        }
                        isInvalid={validationErrors.email}
                      />
                    <img src="/tick.svg" alt="" onClick={changeEmail} className='my-1 curse' style={{ width: '30px', height: '30px', marginRight: '10px' }}/>
                    <img src="/wrong.svg" alt="" onClick={exitEdit} className='my-1 curse' style={{ width: '30px', height: '30px', marginRight: '10px' }}/>

                          </div>
                          {validationErrors.email && (
                            <p className="error-message2">{validationErrors.email}</p>
                          )}
                          {error && <p className="error-message2">{error}</p>}
                          {success && <p className="green-message">{success}</p>}
                    </>
                    ) : (
                      <h3 className='my-5'><img src="/email.svg" alt="" style={{ width: '30px', height: '30px', marginRight: '10px' }}/>{userInfo.Email}<img src="/edit.svg" onClick={enterEdit} alt="" className='mx-3 curse' style={{ width: '30px', height: '30px' }}/></h3>
                  )}
                 
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-4 col-md-6 col-12  " >
            <Card className='radi h-100 my-lg-0 my-md-0 my-3'>
              <Card.Body>
                <Card.Title className='text-center'><h3>Change Your Password</h3></Card.Title>
                <Card.Text>
                  <div className="wrap my-4">
            <div className="container d-flex justify-content-center">
            <div className="input-field">
              <i className="fas fa-lock"></i>
              <Form.Control id="old" type="password" placeholder="Old Password" onChange={(e) =>
                          handlePassChange("old", e.target.value)
                        } isInvalid={PassValidation.old}/>
            </div>
            </div>
            <div className="container d-flex justify-content-center">
            <div className="input-field">
              <i className="fas fa-lock"></i>
              <Form.Control id="new" type="password" onChange={(e) =>
                          handlePassChange("pass", e.target.value)
                        } placeholder="New Password" isInvalid={PassValidation.pass}/>
            </div>
            </div>
            <div className="container d-flex justify-content-center">
            {PassValidation.pass && (
                      <p className="error-message2">
                        {PassValidation.pass}
                      </p>
                    )}
            </div>
            <div className="container d-flex justify-content-center">
            <div className="input-field">
              <i className="fas fa-lock"></i>
              <Form.Control id="confirmnew" type="password"  onChange={(e) =>
                          handlePassChange("confirm", e.target.value)
                        } placeholder="Confirm Password" isInvalid={PassValidation.confirm}/>
            </div>
            </div>
            <div className="container d-flex justify-content-center">
            {PassValidation.confirm && (
                      <p className="error-message2">
                        {PassValidation.confirm}
                      </p>
                    )}
            </div>
            <div className="container d-flex justify-content-center">
            <button onClick={changePassword} className={getPasswordClassName()} disabled={isChangeDisabled()}>
              Submit
            </button>
            </div>
            <div className="container d-flex justify-content-center">
            {passwordError && <p className="error-message">{passwordError}</p>}
                          {passwordSuccess && <p className="green-message">{passwordSuccess}</p>}
                          </div>
            </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
</div>
        <br />
        <br />
            </div>
            
        </>
          )}
          <Footer></Footer>
        </div>
    )
}

export default Profile;