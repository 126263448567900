import Navbar from "../components/Navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import SimpleCarousel from "../components/Carousel";
import MultiItemCarousel from "../components/MultipleItemCarousel";
import React from "react";
import Footer from "../components/footer";
import AOS from 'aos';
import { useEffect } from "react";
import 'aos/dist/aos.css';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Home = () => {


  const { t } = useTranslation();
    const nav = useNavigate();


    const gotoBook = () => {
        nav("/reservation");
    }


    const gotoDerma = () =>{
        nav("/derma");
        setTimeout (()=> {
          const sectionElement = document.getElementById("top");
          if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
          }
        },100);
        
      };

      const gotoNut = () =>{
        nav("/nutrition");
        setTimeout (()=> {
          const sectionElement = document.getElementById("top");
          if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
          }
        },100);
        
      };


      const gotoDent = () =>{
        nav("/dentistry");
        setTimeout (()=> {
          const sectionElement = document.getElementById("top");
          if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
          }
        },100);
        
      };



    useEffect(() => {
        AOS.init();
      }, []);

    return(
      <div className="wrap">
      <Navbar></Navbar>
      <SimpleCarousel></SimpleCarousel>
      <p></p>
      <div className="container-fluid padding align-items-center">
        <div className="row text-center padding my-5">
          <div className="col-lg-4 offset-lg-2 my-5 display-3 title2" data-aos="fade-right" data-aos-duration="3000">
            {t('scheduleVisit')}
          </div>
          <div className="col-lg-4 align-items-center my-5 d-flex justify-content-center" data-aos="fade-right" data-aos-duration="3000">
            <button className="but col-9 col-lg-7 col-md-7" onClick={gotoBook}>{t('makeAppointment')}</button>
          </div>
        </div>
      </div>
      <div className="container-fluid my-5 py-2 pink">
        <div>
          <h3 className="why display-4 text-center" data-aos="fade-right" data-aos-duration="3000">{t('getToKnowOurOffers')}</h3>
        </div>
        <MultiItemCarousel></MultiItemCarousel>
      </div>
      <br />
      <div className="container-fluid my-5 color" >
                <div className="row" data-aos="fade-up" data-aos-duration="3000">
                    <div className="my-4 col-sm-5 col-md-4 col-lg-4 col-xl-5 col-xxl-4 offset-lg-1 my-lg-5 my-md-5 my-sm-5">
                        <img src="/General.jpg" alt="" className="service "/>
                    </div>
                    <div className=" mb-4 col-sm-7 col-md-7 offset-md-1 col-lg-6 col-xl-5 offset-xxl-1 offset-xl-1  offset-lg-1 my-lg-5  my-xl-5 my-xxl-0 my-sm-3">
                        <p className="display-4 my-xxl-5 serviceTitle my-md-3 why">{t('whyChooseUs')}</p>
                        <h4 className="my-xxl-4 col-xxl-12 paragraph why">{t('ourReputation')}</h4>
                        <button className="box2 my-xxl-3 my-lg-2 col-lg-4 why"> {t('learnMore')}</button>
                    </div>
                </div>
            </div>
      <div className="container-fluid padding align-items-center">
        <div className="row text-center">
          <div className="col-lg-3 col-md-6 col-6">
            <button className="box col-12 col-lg-12" data-aos="fade-right" data-aos-duration="1500" onClick={gotoDent}>{t('dentistry')}</button>
            <img src="SmilingWoman.png" alt="" className="img-fluid" />
          </div>
          <div className="col-lg-3 col-md-6 col-6">
            <button className="box col-12 col-lg-12" data-aos="fade-right" data-aos-duration="1500" onClick={gotoDerma}>{t('dermatology')}</button>
            <img src="Derma.png" alt="" className="img-fluid" />
          </div>
          <div className="col-lg-3 col-md-6 col-6 mt-lg-0 mt-md-0 mt-4">
            <button className="box col-12 col-lg-12" data-aos="fade-right" data-aos-duration="1500">{t('laser')}</button>
            <img src="Laser.png" alt="" className="img-fluid" />
          </div>
          <div className="col-lg-3 col-md-6 col-6 mt-lg-0 mt-md-0 mt-4">
            <button className="box col-12 col-lg-12" data-aos="fade-right" data-aos-duration="2000" onClick={gotoNut}>{t('nutrition')}</button>
            <img src="Balance.png" alt="" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="my-5"> </div>
      <Footer></Footer>
    </div>
    )
}
export default Home;