// Sidebar.js
import React from 'react';
import 'boxicons/css/boxicons.min.css';
import axios from 'axios';
import baseURL from '../../baseURL';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {


  const navigate = useNavigate();

  const toggleSidebar = () => {
    const sidebar = document.querySelector(".sidebar");
    sidebar.classList.toggle("open");
    menuBtnChange();
  };

  const menuBtnChange = () => {
    const sidebar = document.querySelector(".sidebar");
    const closeBtn = document.querySelector("#btn");

    if (sidebar.classList.contains("open")) {
      closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");
    } else {
      closeBtn.classList.replace("bx-menu-alt-right", "bx-menu");
    }
  };


  const logout = () => {
    axios
    .get(`${baseURL}/api/auth/logout`)
    .then((response) => {
      if (response.data.success) {
        localStorage.clear();
        navigate("/stafflogin");
      } else {
  
      }
    })
    .catch((error) => {
          
    });
  }


  return (
    <div className="sidebar">
      <div className="logo-details">
        <div className="logo_name mx-3"><img src="/realogo.png" className='img-fluid sideImg' alt="" /></div>
        <i className="bx bx-menu" id="btn" onClick={toggleSidebar}></i>
      </div>
      <div className="nav-list">
        <li>
          <a href='/viewReservations'>
          <i class='bx bx-calendar'></i>
          <span className="links_name">Reservations</span>
          </a>
          <span className="tooltip">Reservations</span>
        </li>
        <li>
          <a href='/modify'>
          <i class='bx bx-time'></i>
            <span className="links_name">Shifts</span>
          </a>
          <span className="tooltip">Shifts</span>
        </li>
        <li>
          <a href='/addPoints'>
          <i class='bx bx-plus'></i>
            <span className="links_name">Add Points</span>
          </a>
          <span className="tooltip">Add Points</span>
        </li>
        <li>
          <a onClick={logout}>
            <i id="btn" className="bx bx-log-out"></i>
            <span className="links_name">Logout</span>
          </a>
          <span className="tooltip">Logout</span>
        </li>
      </div>
    </div>
  );
};

export default Sidebar;
