//Reservation.jsx
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbaro from "../components/Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import baseURL from "../../baseURL";
import Footer from "../components/footer";

function Reservation() {
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [doctorsData, setDoctorsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedStart, setSelectedStart] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedEnd, setSelectedEnd] = useState("");
  const [shiftData, setShiftData] = useState("");
  const [scheduleId, setScheduleId] = useState("");

  const nav = useNavigate();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const handleDoctorSelection = (doctorName) => {
    setSelectedDoctor(doctorName);
  };

  const toggleDate = () => {
    setCurrentStep(3);
  };


  useEffect(() => {
    axios
      .get(`${baseURL}/api/reservation/fetchAllDoctors`, { withCredentials: true })
      .then((response) => {
        setDoctorsData(response.data.doctors);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          nav("/login");
        }
      });
  }, [nav]);



  const handlePreviousOfDoctors = () => {
    setCurrentStep(1);
  };

  const [selectedSpecialty, setSelectedSpecialty] = useState(null);

  const handleSpecialtyClick = (specialty) => {
    setSelectedSpecialty(specialty);
  };

  const isCardSelected = (specialty) => {
    return selectedSpecialty === specialty;
  };

  const isDoctorSelected = (doctor) => {
    return selectedDoctor === doctor;
  };

  const handleDoctorsTab = () => {
    setCurrentStep(2);
  };

  const handleOfPreviousDate = () => {
    setCurrentStep(2);
  };

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(null);
  const [scheduleData, setScheduleData] = useState([]);

  useEffect(() => {
    // Fetch the doctor's schedule based on the selectedDoctor when the component mounts
    if (selectedDoctor) {
      axios
        .post(`${baseURL}/api/schedule/getScheduleForReservation`, {
          doctorName: selectedDoctor,
        }, { withCredentials: true })
        .then((response) => {
          console.log("Fetched doctor schedule:", response.data);
          setScheduleData(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            nav("/login");
          }
        });
    }
  }, [nav,selectedDoctor]);

  useEffect(() => {
    // Fetch the doctor's schedule based on the selectedDoctor when the component mounts
    if (selectedDay) {
      console.log(selectedDay);
      axios
        .post(`${baseURL}/api/schedule/getAvailableShifts`, {
          doctorName: selectedDoctor,
          date: selectedDay,
        }, { withCredentials: true })
        .then((response) => {
          setShiftData(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            nav("/login");
          }
        });
    }
  }, [nav,selectedDay,selectedDoctor]);

  const daysInWeek = [];
  const currentDateCopy = new Date(currentDate);

  for (let i = 0; i < 7; i++) {
    const day = new Date(currentDateCopy);
    day.setDate(currentDate.getDate() - currentDate.getDay() + i);
    daysInWeek.push(day);
  }

  const handleDayClick = (day) => {
    console.log(day);
    setSelectedDay(day);
  };

  const goToPreviousWeek = () => {
    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 7)));
    setSelectedDay(null);
  };

  const goToNextWeek = () => {
    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 7)));
    setSelectedDay(null);
  };

  const handleToTimes = () => {
    setCurrentStep(4);
  };

  const isBeforeToday = (date) => {
    const today = new Date();
    return date < today;
  };

  const handlePreviousOfTimes = () => {
    setSelectedDay(null);
    setCurrentStep(3);
  };

  const handleSubmit = () => {
    const requestData = {
      userId: userInfo.Id,
      scheduleId: scheduleId, // Use the ID from the matching schedule
    };

    axios
      .post(
        `${baseURL}/api/reservation/createReservation`,
        requestData, { withCredentials: true }
      )
      .then((response) => {
        console.log("Reservation created:", response.data);
        setSelectedDay(null);
        setIsFormSubmitted(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          nav("/login");
        }
      });
  };

  const isShiftStart = (shift) => {
    return selectedStart === shift;
  };

  const isShiftEnd = (shift) => {
    return selectedEnd === shift;
  };
  const isShiftBranch = (shift) => {
    return selectedBranch === shift;
  };

  const handleShiftSelection = (time) => {
    setSelectedStart(time.StartTime);
    setSelectedEnd(time.EndTime);
    setSelectedBranch(time.BranchName);
    setScheduleId(time.Id);
  };

  const monthName = currentDate.toLocaleDateString("en-US", { month: "long" });

  return (
    <div className="wrap">
      {isLoading ? (
        <div className="wrap">
          <div className="d-flex justify-content-center align-items-center min-vh-100">
            <div className="loader"></div>
          </div>
        </div>
      ) : isFormSubmitted ? (
        <div className="wrap bbbb">
          <Navbaro></Navbaro>
          <div className="row text-center my-3">
            <div className="col-lg-4 offset-lg-4">
              <svg
                className="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
              <h3 className="title">
                One of our staff members will contact you shortly.
              </h3>
              <button className="but col-lg-5 col-11 my-4">Home</button>
            </div>
          </div>
        </div>
      ) : (
        <div className="wrappy bbbb">
          <Navbaro></Navbaro>
          <div className="container-fluid">
            <AnimatePresence>
              {currentStep === 1 && (
                <div className="container d-flex justify-content-center align-items-center Vcenter">
                  <motion.div
                    key={1}
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.5 }}
                    layout
                    className="ani col-lg-6"
                    style={{
                      borderRadius: "1rem",
                      boxShadow: "0px 10px 30px rgba(0,0,0, 0.5)",
                    }}
                  >
                    <motion.h1 layout="position" className="headd text-center">
                      Book Your Appointment
                    </motion.h1>
                    <h3 className="text-center">
                      Please choose the speciality
                    </h3>
                    <div className="container d-flex justify-content-center">
                      <ProgressBar
                        animated
                        now={currentStep * 25}
                        className="col-lg-6 col-md-6 col-6 text-center my-4"
                      />
                    </div>
                    <div className="container d-flex my-lg-3">
                      <Card
                        className={`custom-card col-lg-6 col-md-6 mx-md-1 mx-lg-1 col-6 mx-1 ${
                          isCardSelected("Dermatology") ? "selected" : ""
                        }`}
                        onClick={() => handleSpecialtyClick("Dermatology")}
                      >
                        <Card.Body className="custom-body">
                          <Card.Title className="text-center">
                            Derma
                          </Card.Title>
                        </Card.Body>
                      </Card>

                      <Card
                        className={`custom-card col-lg-6 col-md-6 mx-md-1 col-6 mx-1 ${
                          isCardSelected("Dentistry") ? "selected" : ""
                        }`}
                        onClick={() => handleSpecialtyClick("Dentistry")}
                      >
                        <Card.Body className="custom-body">
                          <Card.Title className="text-center">
                            Dentistry
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="container d-flex my-lg-4 my-md-4 my-4 ">
                      <Card
                        className={`custom-card col-lg-6 mx-lg-1 col-md-6 mx-md-1 col-6 mx-1 ${
                          isCardSelected("Nutrition") ? "selected" : ""
                        }`}
                        onClick={() => handleSpecialtyClick("Nutrition")}
                      >
                        <Card.Body className="custom-body">
                          <Card.Title className="text-center">
                            Nutrition
                          </Card.Title>
                        </Card.Body>
                      </Card>

                      <Card
                        className={`custom-card col-lg-6 col-md-6 mx-md-1 col-6 mx-1 col-6 mx-1 ${
                          isCardSelected("Pediatrics") ? "selected" : ""
                        }`}
                        onClick={() => handleSpecialtyClick("Pediatrics")}
                      >
                        <Card.Body className="custom-body">
                          <Card.Title className="text-center">
                            Pediatrics
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="container d-flex justify-content-center my-lg-4 my-md-4 my-4">
                      <Card
                        className={`custom-card  col-lg-6 mx-lg-1 col-md-6 mx-md-1 col-6 mx-1 ${
                          isCardSelected("Laser") ? "selected" : ""
                        }`}
                        onClick={() => handleSpecialtyClick("Laser")}
                      >
                        <Card.Body className="custom-body">
                          <Card.Title className="text-center">Laser</Card.Title>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="container d-flex justify-content-center my-lg-5 my-4">
                      <button className="bot disabled col-lg-3 mx-3" disabled>
                        Previous
                      </button>
                      <button
                        className={`bot col-lg-3 mx-3 ${
                          selectedSpecialty ? "" : "disabled"
                        }`}
                        onClick={handleDoctorsTab}
                        disabled={!selectedSpecialty}
                      >
                        Next
                      </button>
                    </div>
                  </motion.div>
                </div>
              )}
              {currentStep === 2 && (
                <div className="container d-flex justify-content-center align-items-center Vcenter">
                  <motion.div
                    key={2}
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.5 }}
                    layout
                    className="ani col-lg-6"
                    style={{
                      borderRadius: "1rem",
                      boxShadow: "0px 10px 30px rgba(0,0,0, 0.5)",
                    }}
                  >
                    <motion.h1 layout="position" className="headd text-center">
                      Book Your Appointment
                    </motion.h1>
                    <h3 className="text-center">
                      Please choose your preferred doctor
                    </h3>
                    <div className="container d-flex justify-content-center">
                      <ProgressBar
                        animated
                        now={currentStep * 25}
                        className="col-lg-6 col-md-6 col-6 text-center my-4"
                      />
                    </div>
                    <div className="container d-flex justify-content-center my-lg-3">
                      <div className="row justify-content-center">
                        {doctorsData
                          .filter(
                            (doctor) => doctor.Speciality === selectedSpecialty,
                          )
                          .map((doctor, index) => (
                            <Card
                              key={index}
                              className={`custom-card col-lg-5 mx-lg-1 my-md-2 my-2 my-lg-3 ${
                                isDoctorSelected(doctor.Name) ? "selected" : ""
                              }`}
                              onClick={() => handleDoctorSelection(doctor.Name)}
                            >
                              <Card.Body className="custom-body">
                                <Card.Title className="text-center">
                                  Dr. {doctor.Name}
                                </Card.Title>
                              </Card.Body>
                            </Card>
                          ))}
                      </div>
                    </div>
                    <div className="container d-flex justify-content-center my-lg-5">
                      <button
                        className="bot col-lg-3 mx-3"
                        onClick={handlePreviousOfDoctors}
                      >
                        Previous
                      </button>
                      <button
                        className={`bot col-lg-3 mx-3 ${
                          selectedDoctor ? "" : "disabled"
                        }`}
                        onClick={toggleDate}
                        disabled={!selectedDoctor}
                      >
                        Next
                      </button>
                    </div>
                  </motion.div>
                </div>
              )}
              {currentStep === 3 && (
                <div className="container d-flex justify-content-center align-items-center Vcenter3">
                  <motion.div
                    key={3}
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.5 }}
                    layout
                    className="ani col-lg-6 col-11"
                    style={{
                      borderRadius: "1rem",
                      boxShadow: "0px 10px 30px rgba(0,0,0, 0.5)",
                    }}
                  >
                    <motion.h1 layout="position" className="headd text-center">
                      Book Your Appointment
                    </motion.h1>
                    <h3 className="text-center">
                      Please choose one of the available dates
                    </h3>
                    <div className="container d-flex justify-content-center">
                      <ProgressBar
                        animated
                        now={currentStep * 25}
                        className="col-lg-6 col-md-6 col-6 text-center my-4"
                      />
                    </div>
                    <div className="container d-flex justify-content-center my-lg-3">
                      <div>
                        <div className="container-fluid d-flex justify-content-center">
                          <h1 className="my-3">{monthName}</h1>
                        </div>
                        <div className="navigation-buttons container-fluid d-flex justify-content-center my-lg-1 my-md-3">
                          <i
                            onClick={goToPreviousWeek}
                            disabled={
                              isBeforeToday(currentDate) ? "disabled" : ""
                            }
                            className="bx bx-left-arrow-alt arrow-icon"
                          ></i>
                          <i
                            onClick={goToNextWeek}
                            className="bx bx-right-arrow-alt arrow-icon"
                          ></i>
                        </div>

                        <div className="calendar-container container-fluid">
                          <div className="row">
                            {daysInWeek.map((day, index) => {
                              const matchingSchedule = scheduleData.find(
                                (schedule) => {
                                  const scheduleDate = new Date(schedule.Date);
                                  return (
                                    scheduleDate.toDateString() ===
                                    day.toDateString()
                                  );
                                },
                              );

                              return (
                                <div className="col-lg-4 col-xl-3 col-md-3 col-4 col- my-2">
                                  <Card
                                    key={index}
                                    className={`day col-12 mx-md-3 ${
                                      selectedDay &&
                                      selectedDay.toDateString() ===
                                        day.toDateString()
                                        ? "selected"
                                        : ""
                                    } ${
                                      !matchingSchedule ? "unavailable" : ""
                                    }`}
                                    onClick={
                                      !matchingSchedule
                                        ? null
                                        : () => handleDayClick(day)
                                    }
                                  >
                                    <Card.Title
                                      className="col-lg-12"
                                      style={{
                                        color: "white",
                                        backgroundColor: "rgb(206, 142, 152)",
                                        padding: "8px",
                                      }}
                                    >
                                      {day.toLocaleDateString("en-US", {
                                        weekday: "short",
                                      })}
                                    </Card.Title>
                                    <Card.Subtitle>
                                      <h1>{day.getDate()}</h1>
                                    </Card.Subtitle>
                                  </Card>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container d-flex justify-content-center my-lg-5">
                      <button
                        className="bot col-lg-3 mx-3"
                        onClick={handleOfPreviousDate}
                      >
                        Previous
                      </button>
                      <button
                        className={`bot col-lg-3 mx-3 ${
                          selectedDay ? "" : "disabled"
                        }`}
                        onClick={handleToTimes}
                        disabled={!selectedDay}
                      >
                        Next
                      </button>
                    </div>
                  </motion.div>
                </div>
              )}
              {currentStep === 4 && (
                <div className="container d-flex justify-content-center align-items-center Vcenter">
                  <motion.div
                    key={2}
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.5 }}
                    layout
                    className="ani col-lg-6"
                    style={{
                      borderRadius: "1rem",
                      boxShadow: "0px 10px 30px rgba(0,0,0, 0.5)",
                    }}
                  >
                    <motion.h1 layout="position" className="headd text-center">
                      Book Your Appointment
                    </motion.h1>
                    <h3 className="text-center">
                      Please choose one of the available shift times
                    </h3>
                    <div className="container d-flex justify-content-center">
                      <ProgressBar
                        animated
                        now={currentStep * 25}
                        className="col-lg-6 col-md-6 col-6 text-center my-4"
                      />
                    </div>
                    <div className="container d-flex justify-content-center my-lg-3">
                      <div className="row justify-content-center">
                        {shiftData.map((shift, index) => (
                          <Card
                            key={index}
                            className={`custom-card col-lg-12 mx-lg-1 my-lg-3 ${
                              isShiftStart(shift.StartTime) &&
                              isShiftEnd(shift.EndTime) &&
                              isShiftBranch(shift.BranchName)
                                ? "selected"
                                : ""
                            }`}
                            onClick={() => handleShiftSelection(shift)}
                          >
                            <Card.Body className="custom-body">
                              <Card.Title className="text-center">
                                <div>{shift.BranchName}</div>
                                <div>
                                  {shift.StartTime} - {shift.EndTime}
                                </div>
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        ))}
                      </div>
                    </div>
                    <div className="container d-flex justify-content-center my-lg-5">
                      <button
                        className="bot col-lg-3 mx-3"
                        onClick={handlePreviousOfTimes}
                      >
                        Previous
                      </button>
                      <button
                        className={`bot col-lg-3 mx-3 ${
                          selectedBranch && selectedEnd && selectedStart
                            ? ""
                            : "disabled"
                        }`}
                        onClick={handleSubmit}
                        disabled={
                          !selectedStart && !selectedEnd && !selectedBranch
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </motion.div>
                </div>
              )}
            </AnimatePresence>
          </div>
        </div>
      )}
      <Footer></Footer>
    </div>
  );
}

export default Reservation;
