import React, { useState } from "react";
import Navbaro from "../components/Navbar";
import "font-awesome/css/font-awesome.min.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import baseURL from "../../baseURL";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next"; // Import useTranslation from react-i18next

const Login = () => {
  const [isSignUpMode, setSignUpMode] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  const handleSignInClick = () => {
    setError("");
    setSignUpMode(false);
  };

  const handleSignUpClick = () => {
    setError("");
    setSignUpMode(true);
  };

  const navigate = useNavigate();


  const handleForm = () => {
    setFormSubmitted(false);
  };

  const { t } = useTranslation(); // Destructure t and i18n from useTranslation

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirm: "",
  });

  const handleFieldChange = (field, value) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [field]: validateField(field, value),
    }));
  };

  const validateField = (field, value) => {
    switch (field) {
      case "name":
        return value.trim() === "" ? t('translations.pleaseEnterName') : "";
      case "email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : t('translations.invalidEmail');
      case "phone":
        return /^[0-9]{10}$/.test(value) ? "" : t('translations.invalidPhoneNumber');
      case "password":
        const isPasswordValid = value.length >= 8 && /[a-z]/.test(value) && /[A-Z]/.test(value);
        return isPasswordValid ? "" : t('translations.invalidPassword');
      case "confirm":
        const password = document.querySelector("#pass").value;
        return value === password ? "" : t('translations.passwordsDoNotMatch');
      default:
        return "";
    }
  };

  const isSignUpButtonDisabled = () => {
    return (
      validationErrors.name ||
      validationErrors.email ||
      validationErrors.phone ||
      validationErrors.password ||
      validationErrors.confirm
    );
  };

  const getSignUpButtonClassName = () => {
    return isSignUpButtonDisabled() ? "bot col-lg-6 disabled" : "bot col-lg-6";
  };

  const handleSignup = (event) => {
    event.preventDefault();
    setError("");

    const phoneNumber = document.querySelector("#phone").value;
    const password = document.querySelector("#pass").value;
    const name = document.querySelector("#name").value;
    const confirm = document.querySelector("#confirm").value;
    const email = document.querySelector("#email").value;

    const nameValidation = validateField("name", name);
    const emailValidation = validateField("email", email);
    const phoneValidation = validateField("phone", phoneNumber);
    const passwordValidation = validateField("password", password);
    const confirmValidation = validateField("confirm", confirm);

    setValidationErrors({
      name: nameValidation,
      email: emailValidation,
      phone: phoneValidation,
      password: passwordValidation,
      confirm: confirmValidation,
    });

    if (
      nameValidation ||
      emailValidation ||
      phoneValidation ||
      passwordValidation ||
      confirmValidation
    ) {
      return;
    }

    axios
      .post(`${baseURL}/api/auth/signup`, {
        phoneNumber,
        password,
        name,
        confirm,
        email,
      }, {withCredentials:true})
      .then((response) => {
        if (response.data.success) {
          setFormSubmitted(true);
          setError("");
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setError("");

    const phoneNumber = document.querySelector("#phonenumber").value;
    const password = document.querySelector("#password").value;

    axios
      .post(`${baseURL}/api/auth/login`, {
        phoneNumber,
        password,
      }, {withCredentials:true})
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("userInfo", JSON.stringify(response.data.user));
          navigate("/");
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  return (
    <div className="wrap">
      {isLoading ? (
        <div className="wrap">
          <div className="d-flex justify-content-center align-items-center min-vh-100">
            <div className="loader"></div>
          </div>
        </div>
      ) : (
        <div className="wrap">
          <Navbaro></Navbaro>
          {!formSubmitted && (
            <div className={`cont ${isSignUpMode ? "sign-up-mode" : ""}`}>
              <div className="forms-container">
                <div className="signin-signup">
                  <form action="#" className="sign-in-form">
                    <img
                      src="/realogo.png"
                      alt=""
                      className=" mb-5 img-fluid logotwo"
                    />
                    <h2 className="title">{t('translations.signIn')}</h2>
                    <div className="input-field">
                      <i className="fas fa-phone"></i>
                      <input
                        id="phonenumber"
                        type="tel"
                        placeholder={t('translations.phoneNumber')}
                      />
                    </div>
                    <div className="input-field">
                      <i className="fas fa-lock" />
                      <input
                        id="password"
                        type="password"
                        placeholder={t('translations.password')}
                      />
                    </div>
                    <div className="row">
                      <a className="forg" href="/forgetpassword">
                        {t('translations.forgotPassword')}
                      </a>
                    </div>
                    <button onClick={handleLogin} className="bot col-lg-6 my-3">
                      {t('translations.submit')}
                    </button>
                    {error && <p className="error-message">{error}</p>}
                  </form>
                  <Form
                    className="sign-up-form"
                  >
                    <h2 className="title">{t('translations.signUp')}</h2>
                    <Form.Group className="input-field" controlId="name">
                      <i className="fas fa-user" />
                      <Form.Control
                        type="text"
                        placeholder={t('translations.name')}
                        required
                        onChange={(e) =>
                          handleFieldChange("name", e.target.value)
                        }
                        isInvalid={validationErrors.name}
                      />
                    </Form.Group>
                    <Form.Group className="input-field" controlId="email">
                      <i className="fas fa-envelope"></i>
                      <Form.Control
                        type="email"
                        placeholder={t('translations.email')}
                        onChange={(e) =>
                          handleFieldChange("email", e.target.value)
                        }
                        isInvalid={validationErrors.email}
                        required
                      />
                    </Form.Group>
                    {validationErrors.email && (
                      <p className="error-message2">{t('translations.invalidEmail')}</p>
                    )}
                    <Form.Group className="input-field" controlId="phone">
                      <i className="fas fa-phone"></i>
                      <Form.Control
                        type="tel"
                        placeholder={t('translations.phoneNumber')}
                        onChange={(e) =>
                          handleFieldChange("phone", e.target.value)
                        }
                        isInvalid={validationErrors.phone}
                        required
                      />
                    </Form.Group>
                    {validationErrors.phone && (
                      <p className="error-message2">{t('translations.invalidPhoneNumber')}</p>
                    )}
                    <Form.Group className="input-field" controlId="pass">
                      <i className="fas fa-lock" />
                      <Form.Control
                        type="password"
                        placeholder={t('translations.password')}
                        onChange={(e) =>
                          handleFieldChange("password", e.target.value)
                        }
                        isInvalid={validationErrors.password}
                        required
                      />
                    </Form.Group>
                    {validationErrors.password && (
                      <p className="error-message2">
                        {t('translations.invalidPassword')}
                      </p>
                    )}
                    <Form.Group className="input-field " controlId="confirm">
                      <i className="fas fa-lock" />
                      <Form.Control
                        type="password"
                        placeholder={t('translations.confirmPassword')}
                        onChange={(e) =>
                          handleFieldChange("confirm", e.target.value)
                        }
                        isInvalid={validationErrors.confirm}
                        required
                      />
                    </Form.Group>
                    {validationErrors.confirm && (
                      <p className="error-message2">
                        {t('translations.passwordsDoNotMatch')}
                      </p>
                    )}
                    <button
                      onClick={handleSignup}
                      className={getSignUpButtonClassName()}
                      disabled={isSignUpButtonDisabled()}
                    >
                      {t('translations.submit')}
                    </button>
                  </Form>
                </div>
              </div>
              <div className="panels-container">
                <div className="panel left-panel">
                  <div className="content">
                    <h1>{t('translations.newHere')}</h1>
                    <h4 className="words">
                      {t('translations.beginJourney')}
                    </h4>
                    <button
                      className="bot2 transparent"
                      id="sign-up-btn"
                      onClick={handleSignUpClick}
                    >
                      {t('translations.signUpButton')}
                    </button>
                  </div>
                  <img src="img/log.svg" className="image" alt="" />
                </div>
                <div className="panel right-panel">
                  <div className="content">
                    <h1>{t('translations.oneOfUs')}</h1>
                    <h4 className="words">
                      {t('translations.logInContinue')}
                    </h4>
                    <button
                      className="bot2 transparent"
                      id="sign-in-btn"
                      onClick={handleSignInClick}
                    >
                      {t('translations.signIn')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {formSubmitted && (
            <div className="container lan">
              <div className="row my-3">
                <div className="col-lg-4 offset-lg-4">
                  <svg
                    className="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 offset-lg-3 text-center">
                  <h2 className="title">
                    {t('translations.successfullyRegistered')}
                  </h2>
                  <button
                    className="but col-lg-4 col-11 my-5"
                    onClick={handleForm}
                  >
                    {t('translations.logIn')}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Login;
