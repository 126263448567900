import React from "react";
import Navbaro from "../components/Navbar";
import Footer from "../components/footer";
import AOS from 'aos';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Doctors = () =>{
    

    const nav = useNavigate();


    const handleDoctorClick = (Id) => {
      nav(`/doctor/${Id}`);
    }


    useEffect(() => {
        AOS.init();
      }, []);


    return (
        <div className="wrap">
            <Navbaro></Navbaro>
             
              <>
              <div className="position-relative" id="our-mission">
                <img src="Doctors.png" alt="" className="img-fluid darker" />
                <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-50"></div>
                <div className="position-absolute top-50 start-50 translate-middle text-center">
                    <p className="text-white display-2" data-aos="fade-left" data-aos-duration="3000">Our Mission</p>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row my-3">
                    <div className="col-lg-4 offset-lg-2">
                        <h1 data-aos="fade-right" data-aos-duration="3000" className="display-1">At RICCI,</h1>
                        <p>we believe in patient-centered care, unlike what you will find at most medical practices today. From start to finish, it will be a cherished experience, where you will want to return for all of your skincare needs</p>
                        <p>When you call our office, you will be greeted with a friendly voice on the other end rather than a recording, and if you have any questions or issues, you will receive a call back from a physician or nurse. We have a complete registered nursing staff, not only assisting our doctors, but also to assist all of your needs. We pride ourselves on a personalized touch.</p>
                        <p>When it comes to cosmetic treatments, you will leave our office truly excited about your procedure. Our artistic backgrounds combined with a plethora of cosmetic modalities will give you a refreshed and youthful appearance. We believe in treating every patient with a customized approach in order to avoid a “generic” outcome or completely changing your look. All of our providers are up to date on the cutting edge options for your beauty improvements and are happy to share their knowledge.</p>
                    </div>
                    <div className="col-lg-4 my-5">
                        <p>Our tremendous providers actually spend time with their patients, explaining detailed information pertaining to the visit, and building a trusting relationship. Rather than only spending a couple seconds with you, our providers will actually look closely at your skin and will make sure that you understand your diagnosis and the various treatment options that you have. You will also rest assured knowing that our providers are always on call, just a phone call away.</p>
                        <p>From cosmetic services to surgical procedures our team will work diligently to ensure that you receive the best results possible and leave knowing you have found a lifelong partner in skin health. This is who we are and what we stand for. Make an appointment to experience our services yourself!</p>
                    </div>
                </div>
            </div>
            <hr className="line" id="staff" />
            <div className="container-fluid text-center" >
                <h2 className="display-3">Meet Our Experts</h2>
            </div>
            <div className="container-fluid my-5">
                <div className="row">
                <div className="col-lg-2 col-md-6 col-6 offset-lg-1">
                        <img src="/Amr.jpeg" alt="" className="img-fluid" onClick={() => handleDoctorClick(1)}/>
                        <div className="text-center my-3">
                          <h4>Dr Amr Lotfy Monib</h4>
                          <p>Dermatologist</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-6">
                        <img src="Aliaa Bassiouny.jpeg" alt="" className="img-fluid"/>
                        <div className="text-center my-3">
                          <h4>Dr Aliaa Bassiouny</h4>
                          <p>Pediatric Dentist</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-6">
                        <img src="Aya Aboelnadar.jpeg" alt="" className="img-fluid"/>
                        <div className="text-center my-3">
                          <h4>Dr Aya Aboelnadar</h4>
                          <p>Nutritionist</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-6">
                        <img src="Aya Elsharkawy.jpeg" alt="" className="img-fluid"/>
                        <div className="text-center my-3">
                          <h4>Dr Aya Elsharkawy</h4>
                          <p>Dermatologist & Laser Specialist</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-6">
                        <img src="Esraa Elbaz.jpeg" alt="" className="img-fluid"/>
                        <div className="text-center my-3">
                          <h4>Dr Esraa Elbaz</h4>
                          <p>Dermatologist</p>
                        </div>
                    </div>    
                <div className="col-lg-2 col-md-6 offset-lg-1 col-6">
                        <img src="Joman Emara.jpeg" alt="" className="img-fluid"/>
                        <div className="text-center my-3">
                          <h4>Dr Joman Emara</h4>
                          <p>Dermatologist & Laser Specialist</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-6">
                        <img src="May Abdelghafar.jpeg" alt="" className="img-fluid"/>
                        <div className="text-center my-3">
                          <h4>Dr May Abdelghafar</h4>
                          <p>Pediatrician</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-6">
                        <img src="Mohamed Farash.jpeg" alt="" className="img-fluid"/>
                        <div className="text-center my-3">
                          <h4>Dr Mohamed Farash</h4>
                          <p>Dentist</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-6" >
                        <img src="Mohamed Malek.jpeg" alt="" className="img-fluid"/>
                        <div className="text-center my-3">
                          <h4>Dr Mohamed Malek</h4>
                          <p>Nutritionist</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-6">
                        <img src="Mostafa Kamal.jpeg" alt="" className="img-fluid"/>
                        <div className="text-center my-3">
                          <h4>Dr Mostafa Kamal</h4>
                          <p>Dentist</p>
                        </div>
                    </div>
                <div className="col-lg-2 col-md-6 offset-lg-1 col-6">
                        <img src="/Nihal Salem.jpeg" alt="" className="img-fluid"/>
                        <div className="text-center my-3">
                          <h4>Dr Nihal Salem</h4>
                          <p>Dermatologist</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-6">
                        <img src="Nivan Magdy.jpeg" alt="" className="img-fluid"/>
                        <div className="text-center my-3">
                          <h4>Dr Nivan Magdy</h4>
                          <p>Dermatologist & Laser Specialist</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-6">
                        <img src="Nouran Fouda.jpeg" alt="" className="img-fluid"/>
                        <div className="text-center my-3">
                          <h4>Dr Nouran Fouda</h4>
                          <p>Aesthetic Gynecologist & Laser Specialist</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-6">
                        <img src="Omneya Yosry.jpeg" alt="" className="img-fluid"/>
                        <div className="text-center my-3">
                          <h4>Dr Omneya Yosry</h4>
                          <p>Laser Specialist</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-6">
                        <img src="Pakinam Rizk.jpeg" alt="" className="img-fluid"/>
                        <div className="text-center my-3">
                          <h4>Dr Pakinam Rizk</h4>
                          <p>Aesthetician</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 offset-lg-1 col-6">
                        <img src="Sherine Bassiouny.jpeg" alt="" className="img-fluid"/>
                        <div className="text-center my-3">
                          <h4>Dr Sherine Bassiouny</h4>
                          <p>Nutritionist</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-6">
                        <img src="Sherine.jpeg" alt="" className="img-fluid"/>
                        <div className="text-center my-3">
                          <h4>Dr Sherine</h4>
                          <p>Facial Specialist</p>
                        </div>
                    </div>
                </div>            
            </div>
            
            <hr className="line" />
            <div className="container-fluid" id="vip-system">
                <div className="row">
                <div className="text-center">
                    <div className="h3 display-3 my-5" data-aos="fade-left" data-aos-duration="3000">
                            Explore Our Ricci Club & Join Us Now!
                    </div>
                </div>
                </div>
                <div className="row my-3">
          <div className=" col-lg-4 col-md-6 col-12 offset-lg-1 " data-aos="fade-left" data-aos-duration="3000">
            <div className="pricingTable">
              <div className="pricing_heading">
                <h3 className="title">100 points</h3>
                <span className="value">
                  BRONZE
                </span>
              </div>
              <div className="content">
                <ul>
                  <li>5% Discount on all services</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 offset-lg-1" data-aos="fade-up" data-aos-duration="3000">
            <div className="pricingTable">
              <div className="pricing_heading">
                <h3 className="title">200 points</h3>
                <span className="value">
                  SILVER
                </span>
              </div>
              <div className="content">
                <ul>
                  <li>10% Discount on all services</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 offset-lg-1" data-aos="fade-right" data-aos-duration="3000">
            <div className="pricingTable">
              <div className="pricing_heading">
                <h3 className="title">300 points</h3>
                <span className="value">
                  GOLD
                </span>
              </div>
              <div className="content">
                <ul>
                  <li>20% Discount on all services</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                </ul>
              </div>
            </div>
          </div>
        <div className=" col-lg-4 col-md-6 col-12 offset-lg-1" data-aos="fade-left" data-aos-duration="3000">
            <div className="pricingTable">
              <div className="pricing_heading">
                <h3 className="title">400 points</h3>
                <span className="value">
                  PLATINUM
                </span>
              </div>
              <div className="content">
                <ul>
                  <li>30% Discount on all services</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                </ul>
              </div>
            </div>
          </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 offset-lg-1 " data-aos="fade-right" data-aos-duration="3000">
            <div className="pricingTable">
              <div className="pricing_heading">
                <h3 className="title">500 points</h3>
                <span className="value">
                  DIAMOND
                </span>
              </div>
              <div className="content">
                <ul>
                  <li>50% Discount on all services</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                </ul>
              </div>
            </div>
          </div>
            </div>
            <hr className="line" />
            <div className="container-fluid my-5" data-aos="fade-up" data-aos-duration="3000">
                <div className="row">
                
                    <div className="col-sm-5 col-md-4 col-lg-4 col-xl-5 col-xxl-4 offset-lg-1 my-lg-5 my-md-5 my-sm-5">
                        <img src="/Contact.jpg" alt="" className="service "/>
                    </div>
                    <div className="col-sm-7 col-md-7 offset-md-1 col-lg-6 col-xl-5 offset-xxl-1 offset-xl-1  offset-lg-1 my-lg-5  my-xl-5 my-xxl-0 my-sm-3">
                    <h1 className="display-2 my-5">Contact Us Now!</h1>
                        <h1 className="my-md-3 my-lg-5">
                        +201204144442
                        </h1>
                        <p className="lead">66 Horreya road (Fouad Street ٦٦ طريق الحرية (شارع فؤاد, Mahta Al Raml Sq., Alexandria Governorate 21563</p>
                        <p className="lead">66 Horreya road (Fouad Street ٦٦ طريق الحرية (شارع فؤاد, Mahta Al Raml Sq., Alexandria Governorate 21563</p>
                    </div>
                </div>
            </div>
            <div className="container-fluid my-3"></div>
            </>
            <Footer></Footer>
        </div>
    )
}
export default Doctors;