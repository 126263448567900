import React from 'react';
import "../../index.css";
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import baseURL from '../../baseURL';

const Navbaro = () => {
  const nav = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);

  const { t, i18n } = useTranslation();

  axios.defaults.withCredentials = true;

  const logout = () => {
    axios.get(`${baseURL}/api/auth/logout`, { withCredentials: true })
      .then(response => {
        if (response.data.success) {
          localStorage.clear();
          setLoggedIn(false);
          nav("/login");
        }
      })
      .catch(error => {
        console.error('Error logging out:', error);
      });
  };

  useEffect(() => {
    axios
      .get(`${baseURL}/api/patient/getPatient`, { withCredentials: true })
      .then((response) => {
        if (response.data.success) {
          setLoggedIn(true);
        }
      })
      .catch((error) => {
        setLoggedIn(false);
      });
  }, []);

  const gotoSection = (sectionId) => {
    nav("/about");
    setTimeout(() => {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const gotoNut = () => {
    nav("/nutrition");
    setTimeout(() => {
      const sectionElement = document.getElementById("top");
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const gotoDent = () => {
    nav("/dentistry");
    setTimeout(() => {
      const sectionElement = document.getElementById("top");
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const gotoDerma = () => {
    nav("/derma");
    setTimeout(() => {
      const sectionElement = document.getElementById("top");
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const gotoBook = () => {
    nav("/reservation");
  };

  const gotoProfile = () => {
    nav("/profile");
  };

  const gotoHome = () => {
    nav("/");
  };

  const gotologin = () => {
    nav("/login");
  };

  return (
    <Navbar className="tit" expand="md" bg="light" variant="dark" sticky="top">
      <Container>
        <Navbar.Brand href="/">
          <img src="/realogo.png" alt="" className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" className='' />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="ml-auto offset-md-3 offset-lg-4">
            <Nav.Link className="navbar-button" onClick={gotoHome}>{t('navbar.home')}</Nav.Link>
            <NavDropdown title={t('navbar.about')} id="about-dropdown" className="navbar-button custom-dropdown">
              <NavDropdown.Item className="navbar-button" onClick={() => gotoSection('our-mission')}>
                {t('navbar.ourMission')}
              </NavDropdown.Item>
              <NavDropdown.Item className="navbar-button" onClick={() => gotoSection('staff')}>
                {t('navbar.staff')}
              </NavDropdown.Item>
              <NavDropdown.Item className="navbar-button" onClick={() => gotoSection('vip-system')}>
                {t('navbar.vipSystem')}
              </NavDropdown.Item>
              <NavDropdown.Item className="navbar-button" onClick={() => gotoSection('contact-us')}>
                {t('navbar.contactUs')}
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t('navbar.services')} id="services-dropdown" className="navbar-button custom-dropdown">
              <NavDropdown.Item className='navbar-button' onClick={gotoDerma}>{t('navbar.dermatology')}</NavDropdown.Item>
              <NavDropdown.Item className='navbar-button' onClick={gotoDent}>{t('navbar.dentistry')}</NavDropdown.Item>
              <NavDropdown.Item className='navbar-button' onClick={gotoNut}>{t('navbar.nutrition')}</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="navbar-button" onClick={gotoBook}>{t('navbar.book')}</Nav.Link>
            <NavDropdown title={<img src={i18n.language === 'ar' ? '/egypt.svg' : '/uk.svg'} style={{ width: '20px', height: '20px' , marginBottom: '3px' }} alt="Flag" />} className="navbar-button custom-dropdown mx-1 " id="language-dropdown">
              <NavDropdown.Item className='navbar-button' onClick={() => i18n.changeLanguage('en')}>
                {t('navbar.english')}
              </NavDropdown.Item>
              <NavDropdown.Item className='navbar-button' onClick={() => i18n.changeLanguage('ar')}>
                {t('navbar.arabic')}
              </NavDropdown.Item>
            </NavDropdown>
            {loggedIn ? (
              <>
                <NavDropdown title={<i className="fas fa-user" />} id="profile-dropdown" className="navbar-button custom-dropdown col-lg-2 col-md-2 righ">
                  <NavDropdown.Item className="navbar-button" onClick={gotoProfile}>
                    {t('navbar.profile')}
                  </NavDropdown.Item>
                  <NavDropdown.Item className="navbar-button" onClick={logout}>
                    {t('navbar.logout')}
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            ) : (
              <>
                <Nav.Link className="navbar-button  col-md-2 col-lg-2 righ" onClick={gotologin}>
                  {t('navbar.login')}
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navbaro;
