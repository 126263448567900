import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';

function BottomCallout({ message, visible, setVisible, variant = 'danger' }) {
  useEffect(() => {
    if (visible) {
      const timeout = setTimeout(() => {
        setVisible(false);
      }, 3000);

      // Clear the timeout when the component unmounts or when visible changes
      return () => clearTimeout(timeout);
    }
  }, [visible, setVisible]);

  return (
    <Alert
      show={visible}
      onClose={() => setVisible(false)}
      dismissible
      variant={variant}
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 99,
        width: '400px',
      }}
    >
      <div className="d-flex align-items-center">
        <div>
          <Alert.Heading><img
            src={variant === 'danger' ? '/error.svg' : '/success.svg'}
            alt=""
            style={{ width: '25px', height: '25px', display: 'inline-block', marginRight: '10px' }}
            />
            {variant === 'danger' ? 'Error' : 'Success!'}</Alert.Heading>
          <p>{message}</p>
        </div>
      </div>
    </Alert>
  );
}

export default BottomCallout;
