import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import baseURL from "../../baseURL";

const StaffLogin = () => {
  const nav = useNavigate();
  const [error, setError] = useState("");
  const [username, setUsername] = useState(""); // State to store username
  const [password, setPassword] = useState(""); // State to store password

  const handleLogin = (event) => {
    event.preventDefault();
    // Make an Axios POST request to your backend API endpoint
    axios
      .post(`${baseURL}/api/auth/stafflogin`, { username, password }, { withCredentials: true })
      .then((response) => {
        if(response.data.success){
          localStorage.setItem('userInfo', JSON.stringify(response.data.user));
          nav("/viewReservations");
        }
        else{
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setError("Invalid username or password");
      });
  };

  return (
    <div className="container-fluid">
      <div className="login-box">
        <h2 className="display-5">Login</h2>
        <div className="user-box">
          <input
            type="text"
            name="username"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)} // Update username state
          />
          <label>Username</label>
        </div>
        <div className="user-box">
          <input
            type="password"
            name="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)} // Update password state
          />
          <label>Password</label>
        </div>
        <div className="row text-center">
          <button className="bot" onClick={handleLogin}>
            Submit
          </button>
        </div>
        {error && <p className="error">{error}</p>} {/* Display error message */}
      </div>
    </div>
  );
};

export default StaffLogin;