import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Sidebar from "../components/StaffNav";
import axios from "axios";
import baseURL from "../../baseURL";

const AddPoints = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [points, setPoints] = useState("");
  const [error, setError] = useState("");
  const [succ,setSucc] = useState("");

  const handleChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleChangePoints = (e) => {
    setPoints(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
        e.stopPropagation();
      }
    // Make the axios request to your backend
    axios
      .post(`${baseURL}/api/patient/addPoints`, { phoneNumber, points }, {withCredentials : true})
      .then((response) => {
        if (response.data.success) {
          setError("");
          setSucc("Points added successfully");
        } else {
          setSucc("");
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setSucc("");
        setError(error.response.data.message);
      });
  };

  return (
    <div className="wrap">
      <Sidebar />
      <div className="container marge">
        <Form onSubmit={handleSubmit}>
          <h1 className="mb-4">Add Points</h1>
          <Form.Group className="mb-3 col-lg-6" controlId="exampleForm.ControlInput1">
            <Form.Label>Phone Number <span style={{ fontWeight: 'bold' }}>(+20)</span></Form.Label>
            <Form.Control
              type="text"
              placeholder="1201677086"
              value={phoneNumber}
              onChange={handleChangePhoneNumber}
              required
            />
            {error && <p className="error-message my-2">{error}</p>}
          </Form.Group>
          <Form.Group className="mb-3 col-lg-6" controlId="exampleForm.ControlInput2">
            <Form.Label>Points to Add</Form.Label>
            <Form.Control
              type="number"
              placeholder=" 20"
              value={points}
              onChange={handleChangePoints}
              required
            />
          </Form.Group>
          <button type="submit" className="bot">
            Add
          </button>

          {succ && <p className="green-message my-2">{succ}</p>}
          
        </Form>
      </div>
    </div>
  );
};

export default AddPoints;
