import React from "react";
import { useEffect } from "react";
import baseURL from "../../baseURL";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useState } from "react";
import Navbaro from "../components/Navbar";
import Footer from "../components/footer";



const SingleDoctor = () => {
  const { id } = useParams();
  const  [media,setMedia] = useState([]);
  const [doctor,setDoctor] = useState("");



  useEffect(() => {
    console.log(id);
    const fetchData = async () => {
      try {
        const mediaResponse = await axios.post(`${baseURL}/api/media/getMedia`, { docId: id }, { withCredentials: true });
        setMedia(mediaResponse.data.media);
  
        const doctorResponse = await axios.post(`${baseURL}/api/doctor/getDoctorData`, { docId: id }, { withCredentials: true });
        setDoctor(doctorResponse.data.doctor);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [id]);




  const renderMediaContent = (mediaItem, isEven) => {
    const fileExtension = mediaItem.URL.split('.').pop().toLowerCase();
    console.log(media);
    return (
      <>
     
      <div className={`container-fluid my-5 ${isEven ? "even" : "odd"}`} >
        <div className="row">
          <div className={`col-lg-4 ${isEven ? "offset-lg-1" : "offset-lg-1"}`}>
          {fileExtension === 'mp4' ? (
                <video controls className="img-fluid">
                  <source src={`/${mediaItem?.URL}`} type="video/mp4" />
                </video>
              ) : (
                <img src={`/${mediaItem?.URL}`} alt="" className="img-fluid" />
              )}
          </div>
          <div className="col-lg-5 offset-lg-1">
            <p className="my-5 fs-3">{mediaItem?.desc}</p>
          </div>
        </div>
      </div>
      <hr  className="thinLine"/>
      </>
    );
  };

  const renderMediaContent2 = (mediaItem,isEven) => {
    const fileExtension = mediaItem.URL.split('.').pop().toLowerCase();
    return (
      <>
      
      <div className={`container-fluid my-5 ${isEven ? "even" : "odd"}`} >
        <div className="row">
          <div className={`col-lg-5 ${isEven ? "offset-lg-1" : "offset-lg-1"}`}>
            <p className="my-5 fs-3">{mediaItem?.desc}</p>
          </div>
          <div className="col-lg-4 offset-lg-1">
          {fileExtension === 'mp4' ? (
                <video controls className="img-fluid">
                  <source src={`/${mediaItem?.URL}`} type="video/mp4" />
                </video>
              ) : (
                <img src={`/${mediaItem?.URL}`} alt="" className="img-fluid" />
              )}
          </div>
        </div>
      </div>
      <hr className="thinLine"/>
      </>
    );
  };

  const isEven = (index) => index % 2 === 0;


    const paragraphStyle = {
        lineHeight: "2",
        fontSize: "1.2rem",
      };

return(

    <div>
      <Navbaro></Navbaro>
        <div className="container d-flex justify-content-center mt-5">
            <img src={`/${doctor?.Image}`} alt=""className="img-fluid" />
        </div>
        <div className="container mt-2 d-flex justify-content-center">
            <h1 className="text-center">{doctor?.Name}</h1>
        </div>
        <div className="container d-flex justify-content-center">
            <p className="mt-3 mx-5" style={paragraphStyle}>{doctor?.Description}</p> 
        </div>
        <hr className="thinLine"/>
        <div className="mt-5">
          <div className="row text-center">
              <h1>Dr. {doctor?.Name}'s Work</h1>
          </div>
          {media && media.length > 0 ? (
          media?.map((mediaItem, index) => (
            <div key={index}>
              {isEven(index)
                ? renderMediaContent(mediaItem, true)
                : renderMediaContent2(mediaItem, false)}
            </div>
          ))
        ) : (
          null
        )}
    </div>
    <Footer></Footer>
    </div>
)
}

export default SingleDoctor;