import { useState } from "react";
import { RiArrowRightCircleLine , RiArrowLeftCircleLine , RiCloseLine } from "react-icons/ri" ;
import InputDialog from "./InputDialog";
import axios from "axios";
import { useEffect } from "react";
import Sidebar from "../components/StaffNav";
import { useNavigate } from "react-router-dom";
import {
  SevenColGrid,
  Wrapper,
  HeadDays,
  DateControls,
  StyledEvent,
  PortalWrapper
} from "./Calendar.styled";
import { DAYS } from "./const";
import {
  datesAreOnSameDay,
  getDaysInMonth,
  getMonthYear,
  getSortedDays,
  nextMonth,
  prevMonth,
} from "./utils";
import baseURL from "../../baseURL";
import BottomCallout from "../components/BottomCallout";
import Form from 'react-bootstrap/Form';

 const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [showPortal, setShowPortal] = useState(false);
  const [portalData, setPortalData] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('');

  const doctorId = new URLSearchParams(window.location.search).get("doctorId");


  const displayAlert = (message, variant) => {
    setAlertMessage(message);
    setAlertVariant(variant);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 5000);
  };


  const nav = useNavigate();




  const fetchEvents = () => {
    axios
      .get(`${baseURL}/api/schedule/getSchedule?doctorId=${doctorId}`, {
        withCredentials: true,
      })
      .then((response) => {
        const data = response.data.map((event) => {
          // Convert the stored UTC date to the user's local time zone for display
          const localDate = new Date(event.date);
          
          return {
            ...event,
            date: localDate,
          };
        });
        setEvents(data);
        
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          nav("/stafflogin");
        }
      });
  };
  

  useEffect(() => {
    fetchEvents();
    console.log("fetched ",events);
  }, []);

 

  const addEvent = (date ,event  ) => {
    const eventsOnDate = events.filter((ev) => datesAreOnSameDay(ev.date, date));
    
    
    if (!event.target.classList.contains("StyledEvent") && eventsOnDate.length < 3 ) {
    
      setIsDialogOpen(true);
      setSelectedDate(date); }
    
  };




const handleConfirmEvent = (branch, startTime, endTime) => {
  if (selectedDate && branch && startTime && endTime) {
    const branchToColor = {
      Smouha: '#238783',
      Fouad: '#238789',
    };
    const color = branchToColor[branch];

    // Convert the selectedDate to UTC
    const utcDate = new Date(Date.UTC(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate()
    ));

    console.log(utcDate);

    // Get the doctorId from the URL
    const doctorId = new URLSearchParams(window.location.search).get('doctorId');

    axios.post(`${baseURL}/api/schedule/addEvent`, {
      selectedDate: utcDate.toUTCString(), // Send the UTC date as a string to the backend
      branch,
      startTime,
      endTime,
      doctorId,
    }, {
      withCredentials: true,
    })
      .then((response) => {

        displayAlert('Shift added successfully', 'success');  // Display success alert

        fetchEvents();
        setSelectedDate(null);
        setIsDialogOpen(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          nav("/stafflogin");
        }
        displayAlert('Error adding shift', 'danger');
      });
  }
};


  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

 
  
 const handleOnClickEvent = (event) => {
    setShowPortal(true);
    setPortalData(event);
    console.log(portalData);
  };




  const handlePortalClose = () => setShowPortal(false);

  const handleToggleAvailable = () => {
    if (portalData) {
      // Extract the necessary information from portalData
      const { title, date } = portalData;
  
      // Split the title by '-' to get startTime and endTime
      const [branch, timeRange] = title.split(' ');
      const [startTime, endTime] = timeRange.split('-');
  
      // Convert the selected date to UTC
      const utcDate = date.toUTCString();
  
      // Get the doctorId from the URL
      const doctorId = new URLSearchParams(window.location.search).get('doctorId');
  
      axios.post(`${baseURL}/api/schedule/toggleAvailability`, {
        doctorId,
        selectedDate: utcDate,
        branch,
        startTime,
        endTime,
      }, {
        withCredentials: true,
      })
      .then((response) => {


        displayAlert('you have successfully toggled the shift availability', 'success');
  
        const updatedEvents = events.map((ev) =>
        ev.date === portalData.date && ev.title === portalData.title
          ? { ...ev, isAvailable: ev.isAvailable === 1 ? 0 : 1 }
          : ev
      );

      setPortalData(prevData => ({
        ...prevData,
        isAvailable: prevData.isAvailable === 1 ? 0 : 1,
     }));


      setEvents(updatedEvents);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          nav("/stafflogin");
        }
        displayAlert('an error occured', 'danger');
      });
    }
  };




  return (
    <div>
      <Sidebar></Sidebar>
    <Wrapper className="home-section">
    {showAlert && <BottomCallout message={alertMessage} visible={showAlert} setVisible={setShowAlert} variant={alertVariant} />}
      <DateControls>
        
        <button
          onClick={() => prevMonth(currentDate, setCurrentDate)}
          name="arrow-back-circle-outline"
        > <RiArrowLeftCircleLine/></button>
        {getMonthYear(currentDate)}
        <button
          onClick={() => nextMonth(currentDate, setCurrentDate)}
          name="arrow-forward-circle-outline"
        > <RiArrowRightCircleLine/></button>
      </DateControls>
      <SevenColGrid>
        {DAYS.map((day) => (
          <HeadDays className="nonDRAG">{day}</HeadDays>
        ))}
      </SevenColGrid>

      <SevenColGrid
        fullheight={true}
        is28Days={getDaysInMonth(currentDate) === 28}
      >
        {getSortedDays(currentDate).map((day) => (
          <div
            id={`${currentDate.getFullYear()}/${currentDate.getMonth()}/${day}`}

           

            onClick={(e) =>
              addEvent(
                new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  day
                ),
                e
              ) }
         
          >
            <span
              className={`nonDRAG ${
                datesAreOnSameDay(
                  new Date(),
                  new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    day
                  )
                )
                  ? "active"
                  : ""
              }`}
            >
              {day}
            </span>
            <EventWrapper>
              {events.map(
                (ev, index) =>
                  datesAreOnSameDay(
                    ev.date,
                    new Date(
                      currentDate.getFullYear(),
                      currentDate.getMonth(),
                      day
                    )
                  ) && (
                    <StyledEvent 
                      onClick={() => handleOnClickEvent(ev)}
                      className="StyledEvent"
                      id={`${ev.color} ${ev.title}`}
                      key={ev.title}
                      bgColor={ev.color}
                    >
                      {ev.title}
                    </StyledEvent>
                  )
              )}
            </EventWrapper>
          </div>
        ))}
        
        
      </SevenColGrid>
      {isDialogOpen && (
        <PortalWrapper>
        <InputDialog
          onClose={handleDialogClose}
          onConfirm={handleConfirmEvent}
        />
        </PortalWrapper>
      )}
      {showPortal && (
        <PortalWrapper>
      <h2>{portalData.title}</h2>
      <p>{portalData.date.toDateString()}</p>
      <div>

    <div className="container-fluid d-flex">
      <div className="my-2">
        <Form.Check
          type="switch"
          id="custom-switch"
          checked={portalData.isAvailable === 1}  // Replace with your actual state variable
          onChange={handleToggleAvailable}  // Replace with your actual state update function
        />
      </div>
      
      
      <div className="mx-3">
      <button onClick={handlePortalClose} name="close-outline" className="staffbutton"> <RiCloseLine/> </button>
      </div>
      </div>
      </div>
    </PortalWrapper>
      )}

    </Wrapper>
    </div>
  );
};

export default Calendar ;

const EventWrapper = ({ children }) => {
  if (children.filter((child) => child).length)
    return (
      <>
        {children}
      </>
    );
};




